(function()
{
 	var app = angular.module("app");

 	app.run(['functions', function(functions)
 	{

 	}]);

 	app.controller('MapController', ['$scope', '$rootScope', '$http', 'functions', '$compile', function($scope, $rootScope, $http, functions, $compile)
	{
		$scope.perpage 			= 10;
		$scope.init 			= false;

		$scope.initmap = function()
		{
			$scope.myLatlng = new google.maps.LatLng($rootScope.latitude, $rootScope.longitude);
			var mapOptions =
			{
				zoom: 8,
				center: $scope.myLatlng,
				scrollwheel: false
			}
			$scope.map 			= new google.maps.Map(document.getElementById('mapview'), mapOptions);
			$scope.markers 		= [];
			$scope.infowindows 	= [];
			$scope.init 		= true;

			// $scope.setmarkers();
		};

		$scope.closewindows = function()
		{
			for(var i=0; i<$scope.infowindows.length; i++)
			{
				$scope.infowindows[i].close();
			}
		}

		$scope.setmarkers = function()
		{
			if(!$scope.init)
			{
				$scope.initmap();
			}

			for(var i=0; i<$scope.markers.length; i++)
			{
				$scope.markers[i].setMap(null);
			}

			$scope.markers 		= [];
			$scope.infowindows 	= [];

			var mk0 = new google.maps.Marker(
			{
				position: $scope.myLatlng,
				map: $scope.map,
				title: 'Search Location'
			});

			var iw0 = new google.maps.InfoWindow(
			{
      			content: "<h1>Search Location</h1>"
  			});

  			$scope.add_home_marker(mk0, iw0, null);

  			var limit = $scope.$parent.organizations.length;

  			if(limit > $scope.perpage)
  			{
  				limit = $scope.perpage;
  			}

			for(var i=0; i<limit; i++)
  			{
  				// var j = (i-1);

  				try
  				{
	  				var mk = new google.maps.Marker(
					{
						position: new google.maps.LatLng($scope.$parent.organizations[i].latitude,  $scope.$parent.organizations[i].longitude),
						map: $scope.map,
						title: $scope.$parent.organizations[i].name
					});

					var iw = new google.maps.InfoWindow();

					$scope.addmarker(mk, iw, $scope.$parent.organizations[i]);
				}
				catch(e){}
  			}

  			iw0.open($scope.map, mk0);
		}

		$scope.add_home_marker = function(marker, infowindow, organization)
		{
			$scope.markers[$scope.markers.length] = marker;
			$scope.infowindows[$scope.infowindows.length] = infowindow;

			google.maps.event.addListener(marker, 'click', function()
			{
	    	    $scope.closewindows();
    			infowindow.open($scope.map, marker);
  			});

			// Force a repaint
  			google.maps.event.trigger($scope.map,'resize')
  			$scope.map.setCenter(marker.position);
		}

		$scope.addmarker = function(marker, infowindow, organization)
		{
			$scope.markers[$scope.markers.length] = marker;
			$scope.infowindows[$scope.infowindows.length] = infowindow;

			google.maps.event.addListener(marker, 'click', function()
			{
				$scope.preview_org 	= organization;
				var content 		= '<div vcard-organization-map></div>';
          		var compiled 		= $compile(content)($scope);
          		infowindow.setContent(compiled[0]);

	    	    $scope.closewindows();
    			infowindow.open($scope.map, marker);
  			});
		}

		jQuery(document).ready(function()
		{
			// $scope.initmap();

			// jQuery(".nv-tab-tile-trigger").click(function()
			// {
			// 	$scope.view = "tiles";
			// 	$scope.$digest();
			// });

			jQuery(".nv-tab-map-trigger").click(function()
			{
				// $scope.view = "map";
				// $scope.$digest();
				setTimeout(function() { $scope.setmarkers() }, 550);
			});
		});

        $scope.$parent.$watch
		(
			function(scope)
			{
				return $scope.$parent.map_organizations().length;
			},
          	function(newValue, oldValue)
          	{
          		if(newValue == oldValue) { return; }
          		$scope.setmarkers();
          	}
        );

        $scope.$watch
		(
			function(scope)
			{
				return $scope.perpage;
			},
          	function(newValue, oldValue)
          	{
          		if(newValue == oldValue) { return; }
          		$scope.setmarkers();
          	}
        );

        $rootScope.$watch
		(
			function(scope)
			{
				return $rootScope.latitude;
			},
          	function(newValue, oldValue)
          	{
          		if(newValue == oldValue) { return; }
          		$scope.initmap();
          	}
        );

        $rootScope.$watch
		(
			function(scope)
			{
				return $rootScope.longitude;
			},
          	function(newValue, oldValue)
          	{
          		if(newValue == oldValue) { return; }
          		$scope.initmap();
          	}
        );
	}]);
})();
